<template>
    <div class="batchApproval">
        <Header backType="white" @backEvent="$router.go(-1)" :back="true">
            <span slot="headText" style="font-size:.17rem;">批量审批</span>
        </Header>
        <div class="list">
            <Item5Detail2 ref="item5" v-if="mealList.length" :batchApproval='true' @checkChange="checkChange" :mealList="mealList" @open="open"></Item5Detail2>
            <div style="height: 0.08rem;width: 100%;"></div>
            <Item7Detail2 v-if="takeoutList.length" ref="item7" :batchApproval='true' @checkChange="checkChange" :takeoutList="takeoutList"></Item7Detail2>
        </div>
        <div class="bottmBtn">
            <div class='check'>
                <Checkbox v-model="allChecked" @click="handleCheckAll" shape="square">
                    <span class='black ft14'>
                        全选
                    </span>
                    <span>
                        已选择<span class='black'>{{ item7Check.length  + item5Check.length }}</span>条
                    </span>
                </Checkbox>
            </div>
            <div class="subBtn" @click="submit">
                批量确认
            </div>
        </div>
    </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import Item5Detail2 from "@/components/OrderList/Item5Detail2.vue";
import Item7Detail2 from "@/components/OrderList/Item7Detail2.vue";
import { SmartStorage } from "smart-core-util";
import { Checkbox, Dialog } from 'vant';
export default {
    name: "batchApproval",
    components:{
        Item5Detail2,
        Item7Detail2,
        Checkbox,
    },
    data() {
        return {
            mealList: [],
            takeoutList: [],
            allChecked: false,
            item5Check:[],
            item7Check:[],
        };
    },
    watch:{
        item5Check(){
            if((this.item5Check.length + this.item7Check.length) == (this.mealList.length + this.takeoutList.length)){
                this.allChecked = true
            }else{
                this.allChecked = false
            }
        },
        item7Check(){
            if((this.item5Check.length + this.item7Check.length) == (this.mealList.length + this.takeoutList.length)){
                this.allChecked = true
            }else{
                this.allChecked = false
            }
        }
    },
    computed: {
        
    },
    mounted() {
        let params = {
            proposalId: SmartStorage.get("eventData").proposalId,
            withTaskId: true,
            onlyShowConfirm: true
        };
        this.getMealList(params)
        this.getTakeoutList(params)
    },
    methods: {
        handleCheckAll(){
            this.$refs.item7?.checkAll(this.allChecked)
            this.$refs.item5?.checkAll(this.allChecked)
        },
        submit(){
            Dialog.alert({
                title: "批量确认订单",
                message: '请再次确认您要批量确认订单吗？',
                showCancelButton: true,
            }).then(()=>{
                    let arr 
                    arr = this.item5Check.concat(this.item7Check)
                    let params = {
                        TaskIds: arr,    
                        actCode: 'approved'
                    };
                    miceService.BatchPerformTask(params).then(res => {
                        if (res && res.success) {
                            this.toast('批量审批成功');
                            this.$router.push('/orderList');
                        } else {
                            this.toast(res.msg);
                        }
                    });
            }).catch((err) => {
                // on cancel
                    console.log(err);
            })
        },
        checkChange(e){
            switch (e.type) {
                case 'item5':
                    this.item5Check = e.list
                    break;
                case 'item7':
                    this.item7Check = e.list
                    break;
            }
        },
        urlConversion(path) {
            if(!path){
                return ''
            }
            let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
            path = path.replace(reg, "https://$2$3$4$5$6");
            return path
        },
        getMealList(params) {
            miceService.getMealList(params).then((res) => {
                if (res && res.success) {
                    let index = 1
                    res.content.map((item) => {
                        item.itsItemData.shopImg = this.urlConversion(item.itsItemData.shopImg)
                        if (item.isExtra && (item.itsItemData.payProfile.PayModes || [])[0] != 7 && item.changeShop) {
                            for (let i = 0; i < item.itsButtons.length; i++) {
                                if(item.itsButtons[i].txt == '详情'){
                                    index = i
                                }
                            }
                            item.itsButtons.splice(index, 0, {
                                forbidden: false,
                                msg: null,
                                processor: "changeShop",
                                txt: "换餐厅",
                            });
                        }
                    });
                    this.mealList = res.content;
                }
            });
        },
        getTakeoutList(params) {
            miceService.getTakeoutList(params).then((res) => {
                if (res && res.success) {
                    this.takeoutList = res.content;
                }
            });
        },
    }
};
</script>

<style lang="scss">
.batchApproval{
    margin: 0 auto;
    width: 100%;
    .list{
        width: 95%;
        padding: .1rem 0;
        margin: 0 auto;
    }
}
.bottmBtn{
    display: flex;
    padding: .2rem 5%;
    width: 90%;
    align-items: center;
    background: #fff;
    position: fixed;
    bottom: 0;
    .check{
        flex-grow: 1;
        text-align: center;
        .van-checkbox__icon {
            font-size: 18px;
        }
        span{
            color: #979797;
            font-size: .12rem;
        }
        .black{
            color: #13161A;
        }
        .ft14{
            font-size: .14rem!important
        }
    }
    .subBtn{
        flex-grow: 1.7;
        height: .4rem;
        background: var(--themeColor);
        border-radius: .05rem;
        color: #fff;
        line-height: .4rem;
    }
}
</style>